import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import girWallpaperImage from '../assets/images/blog/grant-impact-reporting-wallpaper.png';
import girImage1 from '../assets/images/blog/grant-impact-reporting-1.png';
import girImage2 from '../assets/images/blog/grant-impact-reporting-2.png';
import girImage3 from '../assets/images/blog/grant-impact-reporting-3.png';
import girImage4 from '../assets/images/blog/grant-impact-reporting-4.png';
import grantClassifierWallpaperImage from '../assets/images/blog/grant-classifier-wallpaper.png';
import artificialIntelligenceWallpaperImage from '../assets/images/blog/artificial-intelligence-wallpaper.jpg';

const blogPages = [
  {
    code: 'grant-impact-reporting',
    author: "Lotus Rise",
    title: "Our Grant Impact Reporting tool in six simple steps.",
    image: girWallpaperImage,
    sections: [
      {
        content: 'In the words of Peter Drucker, “You can’t improve what you don’t measure." Your journey toward a more effective allocation of your grants begins with monitoring and evaluating the impact that they have.'
      },
      {
        content: "Yes, but how?"
      },
      {
        content: "Tracking funded work manually is a tiresome endeavor where errors and omissions can occur. It's especially complicated when the work that's been funded through a grant you awarded hasn't yet been published."
      },
      {
        content: "How can we make it easier for foundations like yours to streamline this process, improving the effectiveness of your awarded grants' and their impact's visibility?"
      },
      {
        content: "That's the question that we were trying to answer when we came up with the Grant Impact Reporting (GIR) tool."
      },
      {
        content: "This unique solution will be a precious addition to your reporting toolbox. It will enable grantees to easily report on the work they are doing, and allow foundations like yours to effortlessly monitor and evaluate the outcome of the funding they are providing. Allow us to guide you on how this tool can transform your grant management in six simple steps."
      },
      {
        title: "1. Seamless and secure access",
        content: "Grantees visit your foundation's website, where they access the GIR portal. Our login is simple and secure thanks to Single Sign-On (SSO) authentication. We wanted to ensure that logging in was easy to free the grantees from the hassle of remembering multiple passwords - in turn, this reduces barriers to engagement. A secure login, on the other hand, provides peace of mind. Together, they allow grantees to focus on what really matters: reporting their work."
      },
      {
        title: "2. Easy input of unpublished work",
        content: "Once they have logged in, grantees can immediately start reporting on their grant-related works that have not yet been published. The Grant Impact Reporting tool’s intuitive interface makes this process straightforward and user-friendly. This way, even the less tech-savvy grantees can navigate it effortlessly. By allowing grantees to report unpublished work, your foundation gains a more comprehensive view of the research and projects in progress. This helps you to stay informed about all the efforts that your funding supports.",
        images: [girImage1, girImage2],
      },
      {
        title: "Automatic population of published work",
        content: "This next step is done for you. In fact, one of the standout features of the Grant Impact Reporting tool is its ability to automatically populate published work. The system's integration with CrossRef and OpenAlex uses the grantee's email address and ORCID identifier as well as the grant's DOI number to pull the published works that the grantee has written for that specific grant, without requiring any manual input. This reduces the administrative burden on grantees and ensures that their published output is accurately reflected in your reports. This way, you can showcase the full scope of your achievements."
      },
      {
        title: "Integrated Business Intelligence and reporting",
        content: "Now that all your necessary data is in one place, you can leverage the GIR integration with your existing Business Intelligence (BI) platform to generate reports, track key metrics, and gain valuable insights into the grants you have awarded. Whether you need to produce basic reports for internal review or a more detailed analysis for your stakeholders, the Grant Impact Reporting tool enhances your BI capabilities, so that your data-driven decisions can more thoroughly reflect the real impact of your funding.",
        images: [girImage3]
      },
      {
        title: "Comprehensive grant and work management",
        content: "The Grant Impact Reporting tool doesn’t just stop at data collection and reporting—it also provides you with an intuitive interface to view and manage all your grants and associated works. You can monitor the status, progress, and impact of each grant at a glance, fostering smooth collaboration and stronger accountability between grantees and your foundation. This allows you to stay on track with the success of your funding and identify areas of improvement.",
        images: [girImage4]
      },
      {
        title: "Showcasing your grants' impact",
        content: "Showcasing the impact of your grants has never been this easy. The GIR tool harnesses the power of AI, Machine Learning, and advanced analytics so that you can make more strategic grant decisions and gain a clearer understanding of their impact. This data-centric view of grantmaking will help you reduce hidden and apparent biases so that your funding is allocated based on solid evidence.",
      },
      {
        content: "The GIR tool is designed to be a reliable partner in your grant management journey. From its seamless SSO authentication to its user-friendly interface, every aspect of the tool is designed to provide a hassle-free experience for both grantees and foundations. Whether you're looking to enhance your BI reporting, reduce administrative burdens, or simply gain a clearer understanding of your grants' impact, the GIR tool is the all-in-one solution you can trust. In these six simple steps, you can gain access to all the necessary information, and make more strategic decisions based on accurate, comprehensive data."
      }
    ]
  },
  {
    code: 'grant-classifier',
    author: "Lotus Rise",
    title: "How We Improved JTF's Grant Classification and Trend Analysis Using Artificial Intelligence.",
    image: grantClassifierWallpaperImage,
    sections: [
      {
        content: '“Partnering with Lotus Rise has helped us streamline our team’s evaluative workflows and pilot new technologies in ways that consistently help us better understand the impact of our funding and learn how to improve our grantmaking.”'
      },
      {
        content: "John Templeton Foundation is a philanthropic organization that funds interdisciplinary research. Their mission is to start awe-inspiring conversations. They strive for a world where people are curious about the wonders of the universe and seek to live purposeful lives."
      },
      {
        title: "Challenges:",
        content: "JTF needed a strong and efficient mechanism to analyze and classify the numerous grant applications and academic publications they receive. Manual processes were cumbersome and inconsistent, existing proprietary meta databases were inadequate and expensive, and Machine Learning and Neuro Linguistic Programming required lots of data and excellent training sets."
      },
      {
        content: "This translated into a set of potential challenges:",
        list: [
          "Inefficiency in operations due to manual application processing",
          "Evaluation inconsistency and quality variability",
          "An inadequate utilization of data, with limited insights and data silos",
          "Issues with scalability due to the unsustainability of manual processes",
          "High costs if using proprietary meta-databases",
          "Data integrity and security risks in case of manual processing"
        ]
      },
      {
        content: "That’s why they decided to partner up with us to find an optimal way to classify their grants."
      },
      {
        content: "JTF is one one of the few foundations their size to allow people to submit their ideas to them on any topic. However, they also have prioritized, strategic areas they focus on. Given that the vast majority of JTF’s grants are used to fund academic research, the John Templeton Foundation required a reliable way to decipher research trends while maintaining uniformity in their grant classification."
      },
      {
        content: "Lotus Rise adapted OpenAlex's text classification tool, integrating the meta-database to add a number of topics that were particularly relevant for JTF, and making it API-callable so that it would classify any text input provided by JTF, including the titles and abstracts of the applications they received."
      },
      {
        content: "Our Step-by-Step Process:",
        list: [
          "Understanding JTF's unique requirements for grant classification.",
          "Adding additional, ad-hoc topics, and fine-tuning OpenAlex’s classification model.",
          "Making the classification tool API-callable.",
          "Ensuring accuracy and reliability through thorough testing."
        ]
      },
      {
        content: "The Results:",
        list: [
          "Lotus Rise extended OpenAlex's technology to classify text on-demand and to include additional terms in its model.",
          "JTF was able to pilot emerging classification technologies for its grant applications and academic publications.",
          "The improved classification technology produced more informative results than other classification models JTF was testing, helping JTF to identify trends in grant applications and publications it had supported."
        ]
      },
      {
        content: "Future Plans: JTF and Lotus Rise will continue exploring AI and machine learning applications to further enhance John Templeton Foundation’s classification system."
      },
      {
        content: "Are you ready to leverage cutting-edge technology for your organization's needs? Contact us."
      }
    ]
  },
  {
    code: 'grant-classifier-new',
    author: "Lotus Rise",
    title: "Bridging the Tech Gap to Amplify Nonprofit Organizations' Global Impact.",
    image: artificialIntelligenceWallpaperImage,
    sections: [
      {
        content: 'My ties to the nonprofit space go way back - I’ve been working closely with them, I have invested in them, and I even founded one myself.'
      },
      {
        content: 'I’m in awe of their efforts to create a better world, championing essential causes ranging from equality and access to healthcare to environmental preservation, human rights, and much more.'
      },
      {
        content: 'If you ever wonder just how impactful they are, consider this: in 2020 alone, charitable contributions made by nonprofits and foundations in the United States reached nearly $450 billion. It’s a large sum, but it could be much higher. Let me tell you how.'
      },
      {
        content: "Foundations and nonprofit organizations are the voice of kindness in what can sometimes feel like a world driven by greed, and they should be able to access all the necessary tools to succeed in their mission. However, it’s become painfully evident, throughout the years, that most of them are far behind when it comes to adopting new technological paradigms. The work they do is critical and, unfortunately, many of their activities are limited by inadequate technological support."
      },
      {
        content: 'Foundations and nonprofit organizations face several barriers to entry when trying to access technology, the main ones being budgetary limitations and a lack of technical expertise that can make nonprofit organizations hesitant to embrace change, especially when overwhelmed by a multitude of options.'
      },
      {
        content: 'Contemporary technology has the potential to empower them on different levels. It can boost their efficiency, streamline data management and reporting for smarter decision-making, deepen their engagement to drive more meaningful donations, reduce costs for a roomier budget, enhance collaboration for greater collective impact, and so much more.'
      },
      {
        content: "Technology is no longer a luxury that nonprofits and foundations can afford to do without, and they’re starting to realize it. The Nonprofit Technology Enterprise Network reported that 92% of nonprofits expressed that technology is crucial to the success of their organizations."
      },
      {
        content: "This is where Lotus Rise comes in. We strive to close the technological gap that puts many nonprofit organizations at a disadvantage. We support foundations and nonprofits by providing access to tech solutions and relevant knowledge that help improve their performance and bring their missions to life."
      },
      {
        content: "Our strategy involves the development of a marketplace through which foundations, government agencies, nonprofit organizations, volunteers, and vendors can interact. Our goal is to establish a synergy between technology and philanthropy."
      },
      {
        content: "In our view, foundations and nonprofits are absolutely essential to building a better, fairer, and more sustainable future. However, for them to fully realize their potential, they have to be equipped with contemporary technological solutions that fit their needs. That way, they will have everything they require to succeed."
      },
      {
        content: "Do you want to see how we apply this? Read about how we used Artificial Intelligence to improve the John Templeton Foundation’s grant classification and trend analysis."
      }
    ]
  }
];

const BlogPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const blogData = useMemo(() => {
    const blogCode = params.code;

    return blogPages.find((item) => item.code === blogCode);
  }, [params.code]);

  return (
    <PageLayout>
      <div className="page-blog">
        {
          blogData ? (
            <>
              <img src={blogData.image} className="main-bg-image" alt="Main background" />
              <div className="summary">
                <div className="title">
                  {blogData.title}
                </div>
              </div>
              <div className="content-wrapper">
                <div className="author">By {blogData.author}</div>
                {
                  blogData.sections.map((section) => (
                    <div className="blog-section">
                      {
                        section.title ? (
                          <h4 className="title">{section.title}</h4>
                        ) : null
                      }
                      {
                        section.content ? (
                          <p className="section-content">{section.content}</p>
                        ) : null
                      }
                      {
                        section.extraContent ? (
                          <p className="section-content">{section.extraContent}</p>
                        ) : null
                      }
                      {
                        section.list ? (
                          <ul className="list">
                            {
                              section.list.map((listItem) => (
                                <li className="list-item">{listItem}</li>
                              ))
                            }
                          </ul>
                        ) : null
                      }
                      {
                        section.images ? (
                          <div className="image-list">
                            {
                              section.images.map((image) => (
                                <img className="image" src={image} alt="Blog section" />
                              ))
                            }
                          </div>
                        ) : null
                      }
                    </div>
                  ))
                }
              </div>
            </>
          ) : <div>Blog page not found</div>
        }
      </div>
    </PageLayout>
  );
};

export default BlogPage;
